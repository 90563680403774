type AccountType = 'Professional' | 'Private';

export interface Publisher {
  type: 'Account';
  id: string;
  realm: 'subito';
  accountType: AccountType;
}

export default function transform(advertiser: {
  id: string;
  type: string;
}): Publisher {
  return {
    type: 'Account',
    id: advertiser.id,
    realm: 'subito',
    accountType: advertiser.type === '1' ? 'Professional' : 'Private',
  };
}
