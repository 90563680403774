import type { DLCategory } from '@client/bridges/DataLayer';
import { slugify } from '@sbt-web/utils';

export default function transform(rawCategory: DLCategory): string {
  const { parent, child } = rawCategory;
  if (parent.id === '0') {
    return '';
  } else if (parent.id === '27') {
    return `altro>${slugify(child.name)}`;
  } else if (parent.id === child.id) {
    return slugify(parent.name);
  }

  return `${slugify(parent.name)}>${slugify(child.name)}`;
}
